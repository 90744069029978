<script lang="ts">
  import type {Suggestion} from "../../generated";
  import type {SqrlListItemDetails, SqrlListItemModel} from "../../types/squirrel";
  import {onDestroy} from "svelte";
  import {SqrlTestId} from "../../constants/squirrel";
  import {keywords} from "../../store/SuggestionStore";
  import {timeSuggest, timeSuggestRender} from "../../times/TimeFactory";
  import {indexStore} from "../../store/IndexStore";
  import {SUBMIT_TYPE, submitStore} from "../../store/SubmitStore";
  import SqrlListItemV2 from "../interactive/listItem/SqrlListItemV2.svelte";
  import {COLOR} from "../../constants/color";

  const unsubscribe = keywords.subscribe((value) => {
    indexStore.updateMaxIndex(value.length);
  });
  timeSuggest.mark();
  timeSuggestRender.measure();

  function handleClick(event: CustomEvent<SqrlListItemDetails>) {
    timeSuggest.measure();
    submitStore.handleSubmit({term: event.detail.term}, event.detail.target, SUBMIT_TYPE.SUGGEST_ITEM);
  }

  function createModel(suggestion: Suggestion, index: number): SqrlListItemModel {
    return {
      name: "searchSuggest",
      suggestion,
      index,
      leftIcon: {
        iconTarget: suggestion.iconTarget,
        icon: suggestion.icon
      },
      rightIcon: {
        icon: "arrow-right",
        style: {color: COLOR.RED_100},
        classNames: ["squirrel_arrow_right-icon"]
      }
    }
  }

  onDestroy(() => {
    timeSuggest.measure();
    unsubscribe();
  })
</script>

<div class="squirrel_searchSuggestions" data-testid={SqrlTestId.SUGGESTION_LIST}>
    <ul class="squirrel_searchSuggestions__list squirrel_border_top">
        {#each $keywords as suggestion, index}
            <SqrlListItemV2
                params={createModel(suggestion, index)}
                on:click={handleClick}
            />
        {/each}
    </ul>
</div>

<style lang="scss">
  @use "@otto-ec/design-tokens/tokens";

  .squirrel_searchSuggestions {
    background-color: tokens.$oc-base-color-white;
    padding: tokens.$oc-semantic-spacing-0;
    border-radius: tokens.$oc-semantic-border-radius-0 tokens.$oc-semantic-border-radius-0 tokens.$oc-semantic-border-radius-100 tokens.$oc-semantic-border-radius-100;

    .squirrel_border_top {
      border-top: tokens.$oc-semantic-border-width-400 solid tokens.$oc-base-color-gray-25;
    }
  }
</style>
